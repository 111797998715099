<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		ref="mapModal"
		v-model="isOpen"
		size="xl"
		:title="modalTitle"
		ok-variant="success"
		:ok-title="okTitle"
		@hidden="onCancel"
		ok-only
		modal-class="mui-animation"
		:fade="false"
	>
		<b-container fluid>
			<div>
				<GoogleMapLoader :map-config="config">
					<template slot-scope="{ google, map }">
						<GoogleMarkerMaker :key="currentAddress.id" :marker="marker" :google="google" :map="map" />
					</template>
				</GoogleMapLoader>
			</div>
		</b-container>
	</b-modal>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { isNil, objReducer } from '~utils';
import { mapSettings } from '@/shared/google/mapSettings';
import GoogleMapLoader from '@/components/GoogleMapLoader';
import GoogleMarkerMaker from '@/components/Makers/MarkerMaker';

export default {
	name: 'TaskMapModal',
	mixins: [languageMessages],
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		address: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	components: {
		GoogleMapLoader,
		GoogleMarkerMaker
	},
	computed: {
		isOpen: {
			get() {
				return this.value;
			},
			set(val) {
				return val;
			}
		},
		modalTitle() {
			return this.FormMSG(1, 'Location');
		},
		okTitle() {
			return this.FormMSG(2, 'Ok');
		}
	},
	data() {
		return {
			currentAddress: null,
			config: null,
			marker: null
		};
	},
	methods: {
		onCancel() {
			this.$emit('task-map:hidden');
		},
		mapConfig() {
			const defaultPos = objReducer(['lat', 'lng'], this.currentAddress);

			return {
				...mapSettings,
				center: defaultPos || { lat: 0, lng: 0 },
				zoom: 10
			};
		}
	},
	watch: {
		address: {
			handler: function (address) {
				if (!isNil(address)) {
					this.currentAddress = address;
					const { lat, lng } = address;
					this.config = this.mapConfig();
					this.marker = {
						id: address.id,
						name: 'Location',
						lat,
						lng,
						position: { lat, lng },
						label: {
							text: 'green supplier',
							color: 'white'
						}
					};
					// console.log({currentAddress: this.currentAddress})
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
