var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "mapModal",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        size: "xl",
        title: _vm.modalTitle,
        "ok-variant": "success",
        "ok-title": _vm.okTitle,
        "ok-only": "",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: { hidden: _vm.onCancel },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("b-container", { attrs: { fluid: "" } }, [
        _c(
          "div",
          [
            _c("GoogleMapLoader", {
              attrs: { "map-config": _vm.config },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ google, map }) {
                    return [
                      _c("GoogleMarkerMaker", {
                        key: _vm.currentAddress.id,
                        attrs: { marker: _vm.marker, google: google, map: map },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }