var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      staticClass: "modal-success",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.renderTitle,
        "ok-variant": "success",
        "ok-title": _vm.FormMSG(2, "Close"),
        size: _vm.size,
        "ok-title-html": "",
        "ok-only": "",
        "no-close-on-backdrop": "",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        ok: _vm.handleClose,
        cancel: _vm.handleClose,
        hidden: _vm.handleClose,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("comments", {
        attrs: {
          "key-value": _vm.dataProps,
          "content-height": _vm.contentHeight,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }