<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		:title="renderTitle"
		class="modal-success"
		v-model="isOpen"
		@ok="handleClose"
		ok-variant="success"
		:ok-title="FormMSG(2, 'Close')"
		:size="size"
		ok-title-html
		ok-only
		@cancel="handleClose"
		@hidden="handleClose"
		no-close-on-backdrop
		modal-class="mui-animation"
		:fade="false"
	>
		<comments :key-value="dataProps" :content-height="contentHeight" />
	</b-modal>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { isNil } from '~utils';
import Comments from '@/components/Chat/Comments';

export default {
	name: 'CommentModal',
	mixins: [languageMessages],
	components: {
		Comments
	},
	props: {
		imageParentType: {
			type: String,
			required: false,
			default: 'comment'
		},
		imageParentSubType: {
			type: String,
			required: false,
			default: 'images'
		},
		keyValue: {
			type: Object,
			required: true,
			default: () => ({
				xid: null,
				imageParentId: 0
			})
		},
		title: {
			type: String,
			required: false,
			default: ''
		},
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		contentHeight: {
			type: [String, Number],
			required: false,
			default: '500'
		},
		size: {
			type: String,
			required: false,
			default: 'lg'
		}
	},
	computed: {
		isOpen: {
			get() {
				return this.value;
			},
			set(value) {
				return value;
			}
		},
		renderTitle() {
			return this.title.length === 0 ? this.FormMSG(1, 'Comments') : this.title;
		}
	},
	data() {
		return {
			dataProps: null
		};
	},
	methods: {
		handleClose() {
			this.$emit('comment-modal:hidden');
		}
	},
	watch: {
		keyValue: {
			handler: function (n) {
				if (!isNil(n) && !isNil(n.xid) && n.xid.length > 0 && !isNil(n.imageParentId)) {
					this.dataProps = n;
				}
			}
		}
	}
};
</script>
<style lang="scss" scoped></style>
